.adminServiceCounterContainer {
    padding-top: 250px;
    height: 100vh;
}

.serviceLabel {
    font-family: 'Signika Negative', sans-serif;
    margin-left: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 760px) {
    .adminServiceCounterContainer {
        padding-top: 0px;
        height: 100vh;
    }
}