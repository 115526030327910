.serviceCounterContainer {
    margin-top: 80px;
    font-family: 'Signika Negative', sans-serif;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.serviceCounterNumber {
    font-size: 100px;
    font-weight: 700;
}