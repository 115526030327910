.navContainer {
    display: flex;
}

.topBar {
    margin-left: 143px;
}

.homeSideNav {
    margin-top: 20vh;
}

.sideNavigation {
    font-size: 30px;
    font-weight: 700;
}

.homeNavLogoContainer-1 {
    position: absolute;
    /* width: 200px; */
    height: 170px;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 50px;
    text-decoration: none;
}

.homeNavLogoAndName-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.homeNavName-1 {
    margin-top: 30px;
    margin-left: 220px;
    font-family: 'Oswald', sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.homeNavLogoContainer-2 {
    display: none !important;
}

.homeNavName-3 {
    font-family: 'Oswald', sans-serif;
    display: inline-block;
    font-weight: 700;
    font-size: 35px;
    margin-top: 5px;
}


/* .navLink {
    font-family: 'Oswald', sans-serif;
    font-size: 15px !important;
    font-weight: 300;
    color: #fff !important;
    text-transform: uppercase;
    
} */

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;

}

.navbar-toggler {
    border: none !important;

}

.adminLogo {
    padding-top: 10px;
    margin-left: 15px;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-item {
    font-family: 'Signika Negative', sans-serif;
}

@media only screen and (max-width: 1080px) {
    .homeNavName-3 {
        font-size: 25px;
        margin-top: -3px;
    }

    .homeSideNav {
        margin-top: 5vh;
    }
}

@media only screen and (max-width: 768px) {
    .homeNavLogoContainer-1 {
        display: none;
    }

    .homeNavName-1 {
        display: none;
    }

    .homeSideNav {
        display: none;
    }

    .topBar {
        margin: 0;
    }

    .homeNavLogoContainer-2 {
        display: block !important;
        display: flex !important;
    }


    .homeNavName-2 {
        font-family: 'Oswald', sans-serif;
        display: inline-block;
        font-weight: 700;
        font-size: 25px;
    }

    .adminLogo {
        padding-top: 10px;
        margin-left: 0px;
    }
}