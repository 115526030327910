.galleryWrap .single {
    width: 200px;
    cursor: pointer;
}

.galleryWrap .single img {
    width: 420px;
    height: 300px;
}

.galleryWrap .single img:hover {
    transform: scale(1.02);
}

.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 1;
    color: #fff;
    font-size: 40px;
    z-index: 9999;
    margin-top: 80px;
    background-color: #fff;
    border-radius: 50%;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
    opacity: 1;
}

.sliderWrap .btnClose {
    top: 40px;
    right: 40px;
}

.sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
}

.sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
}

.fullScreenImage {
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}


.fullScreenImage img {
    max-width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: default;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}


.gallery {
    transition: 0.3s;
}

.gallery:hover .gallery__image {
    filter: brightness(30%);
    transition: 0.3s;
}

.gallery__link {
    margin: 2px;
    overflow: hidden;
}

.gallery__link:hover .gallery__image {
    filter: brightness(100%);
    cursor: pointer;
}

.gallery__image {
    display: block;
    width: 100%;
    transition: 0.3s;
    border-radius: 15px;
    margin-bottom: 20px;
    height: 294px;
}

/* .portfolio-desc {
    font-family: 'Signika Negative', sans-serif;
    margin-left: 30px;
    margin-top: 20px;
} */

@media screen and (max-width: 1080px) {
    .fullScreenImage {
        margin-top: 150px;
        width: calc(100% - 40px);
        height: calc(100% - 350px);
        flex-direction: column;
        align-items: center;
    }

    .sliderWrap .btnClose {
        top: 180px
    }

    .sliderWrap .btnPrev,
    .sliderWrap .btnNext {
        position: fixed;
        cursor: pointer;
        opacity: 1;
        color: #fff;
        font-size: 40px;
        z-index: 9999;
        margin-top: -10px;
        background-color: #fff;
        border-radius: 50%;
    }

    .portfolio-desc {
        margin-left: 0;
    }
}

@media screen and (max-width: 760px) {
    .fullScreenImage img {
        height: 294px;
    }

    .sliderWrap .btnClose {
        top: 270px;
        right: 40px;
    }

    .sliderWrap .btnPrev {
        top: 60%;
        transform: translateY(-50%);
        left: 40px;
    }

    .sliderWrap .btnNext {
        top: 60%;
        transform: translateY(-50%);
        right: 40px;
    }
}