.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-top: -10px;
    margin-left: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c7c7c7;
    -webkit-transition: .4s;
    transition: .4s;
    outline: 1px solid;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    outline: 1px solid;
}

input:checked+.slider {
    background-color: rgb(0, 0, 0);
}

input:focus+.slider {
    box-shadow: 0 0 1px #ffffff;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media screen and (max-width: 760px) {
    .switch {
        margin-left: 0;
    }
}