.loadingCards {
    width: 100%;
    height: 300px;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.loadingCardsImage,
.loadingCardsBtn,
.loadingCardsBtn2 {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}

.loadingCardsImage {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.loadingCardsContent {
    padding: 20px;
}

.loadingCardsBtn {
    height: 35px;
    width: 30%;
    border: none;
}

.loadingCardsBtn2 {
    height: 35px;
    width: 30%;
    float: right;
    border: none;
}