.loginContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.loginForm {
    font-family: 'Signika Negative', sans-serif;
    border-style: double;
    padding: 30px;
}

.loginBtn {
    border: none;
    height: 46px;
    width: 154px;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    letter-spacing: 3px;
    background: linear-gradient(to left, #e2e2e2 50%, #5c5c5c 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-out;
    margin-top: 20px;
}

.loginBtn:hover {
    background-position: left bottom;
    color: #fff;
}

.loading-gif {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

@media screen and (max-width: 1080px) {
    .loginContainer {
        flex-direction: column;
    }

    .s {
        width: 100%;

    }
}