.aboutCompanyContainer {
    margin-top: 80px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.aboutCompanyDesc {
    margin-top: 20px;
    font-family: 'Signika Negative', sans-serif;
    font-size: 22px;
    line-height: 35px;
    text-align: justify;
}