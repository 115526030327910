.portfolioBannerContainer {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url('./../../../assets/banners/1.jpg');
    background-attachment: fixed;
    background-size: cover;
    padding: 250px 0px 150px;

}

.portfolios {
    position: relative;
    margin-top: -100px;
}

@media screen and (max-width: 1080px) {
    .portfolioBannerContainer {
        background-attachment: scroll;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 250px 0px 80px;
    }

    .portfolios {
        margin-top: -50px;
    }
}

@media screen and (max-width: 760px) {
    .portfolioBannerContainer {
        padding: 100px 0px 80px;
    }
}