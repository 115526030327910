.videoPlayerContainer {
    margin-top: 80px;
}

.videoPlayer {
    height: 600px;
    width: 55%;
}

@media screen and (max-width: 1080px) {
    .videoPlayer {
        height: 450px;
        width: 90%;
    }
}

@media screen and (max-width: 760px) {
    .videoPlayer {
        height: 250px;
        width: 90%;
    }
}