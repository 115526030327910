.adminNav {
    font-family: 'Signika Negative', sans-serif;
    text-decoration: none;
    margin-right: 20px;
    font-size: 20px;
}

.adminNavContainer {

    margin-bottom: 40px;
}

@media screen and (max-width: 1080px) {
    .adminNavContainer {
        overflow-x: scroll;
        white-space: nowrap;
        margin-bottom: 40px;
    }
}