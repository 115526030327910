.logoAndName {
    display: flex;
    align-items: center;
}

.navCompanyName {
    font-family: 'Oswald', sans-serif;
    display: inline-block;
    font-weight: 700;
    font-size: 35px;
    margin-top: 5px;
}

.navLink {
    font-family: 'Signika Negative', sans-serif;
    margin-left: 30px;
    cursor: pointer;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;

}

.navbar-toggler {
    border: none !important;
}


.navLink::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: rgb(117, 117, 117);
    transition: width .3s;
}

.navLink:hover::after {
    width: 100%;
}

@media screen and (max-width:1280px) {
    .dropdown-menu {
        width: 50% !important;
    }
}

@media screen and (max-width:1080px) {
    .navCompanyName {
        font-size: 25px;
        margin-top: -3px;
    }
}

@media screen and (max-width: 760px) {
    nav {
        position: sticky !important;
    }

    .navCompanyName {
        font-size: 22px;
    }

    .navLink {
        margin-left: 0px;
        margin-top: 10px;
    }

    .navigation {
        margin-left: 0px;
        margin-top: 10px;

    }

    .navLink:hover::after {
        width: 20%;
    }

    .dropdown-menu {
        margin-left: -0px !important;
    }
}