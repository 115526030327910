.title {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 100px;
    font-weight: 700;
    letter-spacing: 10px;
}

@media screen and (max-width: 760px) {
    .title {
        font-size: 50px;
    }
}